@import "vendor/swatch";

.navbar {
  background-color: #161a1a;
  border-radius: 0;
}
.navbar-dropdown {
  background-color: #161a1a;
}
.btn {
  overflow: hidden !important;
}

.table {
  border-radius: 8px;
  overflow: hidden;
}
.table td.is-selected, .table th.is-selected, .table tr.is-selected {
  background-color: #161a1a;
}
.w-100 {
  width: 100%;
}
.table td {
  vertical-align: middle;
}
.input-span {
  width: calc(100% - 57px);
  &.tag {
    width: calc(100% - 65px);
  }
}
.thumbnail {
  max-height: 250px;
  border-radius: 8px;
  overflow: hidden;
}
.button {
  &.extend {
    width: 100% !important;
    display: block !important;
  }
}
.navbar-item {
  span.new {
    margin-left: 10px;
    padding: 2px 5px;
    font-size: 10px;
    color: #fff;
    border-radius: 3px;
    background-color: #179b80;
    font-weight: bold;
    &::after {
      content: "";
      clear: both;
    }
  }
}
.vis-btn {
  background-color: transparent;
  border: none;
  outline: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.editor {
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  h1, h2, h3, h4, h5, h6 {
    //padding: 15px;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 35px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 25px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
}
.popup-parent {
  position: relative;
}
.popup {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #192422;
  border-radius: 10px;
  display: none;
  align-items: center;
  z-index: 10000;
  input {
    width: 250px;
  }
  &.vis {
    display: flex;
  }
}

@media screen and (max-width: 1023px) {
 .navbar .navbar-menu {
   background-color: #161a1a;
 }
}
